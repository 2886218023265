:root{
  --primary1: #0099fa;
  --primary2-light5: #316edc;
  --primary2-light4: #618eef;
  --primary2-dark1: #003caf;
  --primary2-dark2: #002a92;
  --primary2-dark3: #001a66;
  --primary2: #004FC2;
  --primary5-light5: #FFCF39;
  --primary8: #FF7E12;
  --shadow8: #fff;
  --spotlight-red: #E52900;
  --spotlight-red-tint: #E529000f;
  --cool-gray1: #fff;
  --cool-gray2: #f7f8fb;
  --cool-gray3: #e7ebf2;
  --cool-gray4: #d7dde8;
  --cool-gray5: #b8c3d5;
  --cool-gray6: #9aa8c0;
  --cool-gray7: #7e8eaa;
  --cool-gray8: #627592;
  --cool-gray9: #485b7a;
  --cool-gray10: #2d3f5e;
  --cool-gray11: #17253d;


  --color-action-bar-background: var(--cool-gray2);
  --color-action-bar-border: var(--cool-gray5);
  --color-action-bar-box-shadow: rgba(0,0,0,.05);
  --color-action-bar-text: var(--cool-gray11);
  --color-action-bar-section-divider: var(--cool-gray5);
  --color-action-bar-tab-border-width: 1px;
  --color-action-bar-tab-hover: rgba(162,187,251,.3);
  --color-action-bar-button-background: hsla(0,0%,100%,.08);
  --color-action-bar-button-background-active: var(--cool-gray1);
  --color-action-bar-button-text: var(--cool-gray11);
  --color-action-bar-button-text-active: var(--cool-gray11);
  --color-action-bar-button-box-shadow: 0 2px 2px rgba(23,37,61,.12),0 0 2px rgba(23,37,61,.14),0 1px 3px rgba(23,37,61,.2);
}

body{  
  background-color: #282c34;
}

.app {
  text-align: center;
  /* background-color: #0372e2;
  min-height: 100vh;   */
  display: flex;
  justify-content: center;  
  flex-direction: column;
  padding: 0 30px;
}

.app_body {
  display: flex;
  justify-content: center;
  
  padding: 10px 20px;
  width: 100%;
}


.app_header {
  min-height: 10vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.atres_navbar{
  width: 100%;
  padding: 10px 50px;
  background-color: var(--color-action-bar-background);

  /* background-color: red; */

  /* background: linear-gradient(90deg, var(--primary2-dark2) 0%, var(--primary2-dark2) 53.4%, var(--primary1) 53.4%, var(--primary1) 79.9%, var(--primary5-light5) 79.9%, var(--primary5-light5) 93%, var(--primary8) 93%, var(--primary8) 100%); */
}

.form_container{
  padding: 0;
}

.atresmedia_form h1{
  color: black;
  float: left;
  padding: 30px 0 0 0;
  font-size: 1em;
}

.atresmedia_form .form_group{
  margin-bottom: 20px;
}

label.form-label{
  float: left;
  color: white ;
  background-color: #ED7D31 !important;
  width: 100%;
  padding: 0 5px;
  font-size: 0.6rem;
  margin: 0;
  border-radius: 0.375rem 0.375rem 0 0;
}

.react-daterange-picker__wrapper{
  margin-top: 0;
  padding-top: 0;
}

.react-daterange-picker {
  display: block !important;
  margin-top: 0;
  padding-top: 0;
  font-size: 0.6rem;
}
.react-daterange-picker__wrapper,
.form-select,
.form-control,
.form-control-plaintext{
  border-radius:  0 0  0.375rem 0.375rem;
  border: 1px solid #ced4da;
  padding: .375rem .75rem;

  
}

.form-select,
.form-control,
.form-control-plaintext{
  font-size: 1rem;
}

.react-daterange-picker__wrapper{
  font-size: 0.8rem;
}
.react-calendar{
  font-size: 0.6rem;
}

.react-calendar__navigation{
  margin-bottom: 0.25rem;
}


.form_alert{
  z-index: 1000;
}

.atres_navlink{
  width: 200px;
}

.docs{
  text-align: left;
}

.pdf{
  width: 20px;
  padding-right: 5px;
}

.bookign_list{
  font-size: 10px;
}

table.booking_list thead tr th,
table.booking_list tbody tr td{
  font-size: 10px !important;
}

.atres_form_container{
  background-color: white;
  padding: 5px 15px;
}

.atres_form_footer{
  color: white;
  float: left;
  padding: 15px 15px;
}

.atres_form_footer a{
  color: white !important;
}

.cookie_ack{
  color: #575757;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
}

.atres_form_container ul li{
  text-align: left;
}